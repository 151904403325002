<template>
  <div :class="show == true ? 'tabbaron' : 'tabbar'">
    <div
      class="top"
      style="background-color: #000000; position: absolute; top: 0"
    >
      <div class="outBox"></div>
      <div class="topTxt">
        <!-- 	<div class="topTxt2">
					<span>{{ $t(changeTxt) }}</span>
					<el-dropdown trigger="click">
						<span class="el-dropdown-link">
							{{ $t(languageTxt)
              }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-if="languageType == 2" @click.native="seeTable">中文</el-dropdown-item>
							<el-dropdown-item v-else @click.native="seeTable2">英文</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div> -->
      </div>
      <!-- 导航栏 logo-->
      <div class="logo">
        <div class="logo2" @click="toindex">
          <img class="imgLogo" src="../assets/imges/index/logo.png" alt="" />
        </div>
        <!-- 导航栏 -->
        <div class="menu">
          <div
            :class="active == l1.name ? 'menul1 menuAct' : 'menul1'"
            @mouseenter="overMenu(l1)"
            @mouseleave="leaveMenu(l1)"
            v-for="(l1, id) in tabList"
            :key="id"
          >
            <!-- 一级样式 -->
            <div
              class="menul1name"
              slot="reference"
              v-if="l1.maxLevel == 1"
              @click="topage(l1)"
            >
              {{ l1.name }}
            </div>
            <!-- 二级样式 -->
            <el-popover
              placement="bottom"
              trigger="hover"
              ref="popover"
              v-if="l1.maxLevel == 2"
            >
              <el-scrollbar>
                <div class="l2">
                  <div
                    v-for="(l2, index) in l1.children"
                    :key="index"
                    class="l2text"
                    @click="topage(l2)"
                    style="cursor: default"
                  >
                    {{ l2.name }}
                  </div>
                </div>
              </el-scrollbar>
              <div class="menul1name" slot="reference">
                {{ l1.name }}
              </div>
            </el-popover>

            <!-- 三级样式 -->
            <div v-if="l1.maxLevel == 3">
              <div class="menul1name">
                {{ l1.name }}
                <i
                  v-show="showIcon && l1.children.length > 0"
                  :class="
                    active == l1.name
                      ? 'el-icon-arrow-up'
                      : 'el-icon-arrow-down'
                  "
                ></i>
              </div>
              <div class="triangle-up" v-show="active == l1.name">
                <img src="../assets/imges/tabbar/5.png" alt="" />
              </div>
              <div class="l3" v-show="active == l1.name && show == true">
                <div class="threelevels">
                  <div
                    class="threelevelcard"
                    v-for="(l2, id) in l1.children"
                    :key="id"
                  >
                    <div class="threelevelhead">
                      {{ l2.name }}
                    </div>
                    <div class="threelevelinfo">
                      <div
                        class="threeleveline"
                        v-for="(l3, id) in l2.children"
                        :key="id"
                        @click="topage(l3)"
                        style="cursor: default"
                      >
                        {{ l3.name }}
                      </div>
                    </div>
                  </div>

                  <div class="threelevelcard"></div>
                  <div class="threelevelcard"></div>
                  <div class="threelevelcard"></div>
                  <div class="threelevelcard"></div>
                  <div class="threelevelcard"></div>
                  <div class="threelevelcard"></div>
                  <div class="threelevelcard"></div>
                  <div class="threelevelcard"></div>
                  <div class="threelevelcard"></div>
                  <div class="threelevelcard"></div>
                </div>
                <img
                  src="../assets/imges/tabbar/3.png"
                  alt=""
                  class="threelevelsimg"
                />
              </div>
            </div>

            <!-- 四级样式 -->
            <div v-if="l1.maxLevel == 4">
              <div class="menul1name">
                {{ l1.name }}
                <i
                  v-show="showIcon && l1.children.length > 0"
                  :class="
                    active == l1.name
                      ? 'el-icon-arrow-up'
                      : 'el-icon-arrow-down'
                  "
                ></i>
              </div>
              <div class="triangle-up" v-show="active == l1.name">
                <img src="../assets/imges/tabbar/4.png" alt="" />
              </div>
              <div class="l4" v-show="active == l1.name && show == true">
                <div class="l4list">
                  <div
                    class="l4line"
                    v-for="(l2, id) in l1.children"
                    :key="id"
                    @mouseenter="over(l2)"
                    :class="activeIndex == l2.name ? 'l4lineone' : ''"
                  >
                    <span style="vertical-align: middel">{{ l2.name }}</span>
                    <img
                      src="../assets/imges/tabbar/6.png"
                      alt=""
                      class="l4listimg"
                      v-if="activeIndex == l2.name && l2.children.length > 0"
                    />
                    <img
                      src="../assets/imges/tabbar/7.png"
                      alt=""
                      class="l4listimg"
                      v-else-if="l2.children.length > 0"
                    />
                  </div>
                  <img
                    src="../assets/imges/tabbar/3.png"
                    alt=""
                    class="threelevelsimg"
                  />
                </div>
                <div class="l4table">
                  <div class="l4tablelist">
                    <div class="l4card" v-for="(l3, id) in level3" :key="id">
                      <div class="l4heard">
                        {{ l3.name }}
                      </div>
                      <div class="l4info" v-if="l3.children.length > 0">
                        <div
                          class="l4tableline"
                          v-for="(l4, id) in l3.children"
                          :key="id"
                          @click="topage(l4)"
                          style="cursor: default"
                        >
                          {{ l4.name }}
                        </div>
                      </div>
                    </div>
                    <div class="l4card"></div>
                    <div class="l4card"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="headerbutton">
          <el-autocomplete
            placeholder="搜索"
            class="input"
            prefix-icon="el-icon-search"
            :trigger-on-focus="false"
            :fetch-suggestions="querySearch"
            v-model="seach"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <div class="name">{{ item.name }}</div>
            </template>
          </el-autocomplete>
          <el-button class="button" type="primary" @click="tologin">
            登录
          </el-button>
        </div>
      </div>
      <!-- 	<div class="banner">
				<el-carousel class="bannerBox" v-if="page == 'index'">
					<el-carousel-item v-for="(item, id) in banner" :key="id">
						<img class="img" :src="item.url" />
					</el-carousel-item>
				</el-carousel>
			</div> -->
    </div>
    <router-view class="router"></router-view>
    <footer-tab></footer-tab>
    <div class="online" @click="onlineshow = true">
      <img src="../assets/imges/tabbar/29.png" />
      <span>在线咨询</span>
    </div>
    <div class="onlinelist" v-if="onlineshow">
      <div class="onlinetable">
        <div class="line" @click="onlineshow = false"></div>
      </div>
      <div class="onlineform">
        <el-form
          label-position="top"
          style="width: 80%; margin: 0 auto"
          :model="form"
          :rules="rules"
          ref="form"
        >
          <el-form-item label="名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="联系方式：" prop="mobile">
            <el-input v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="问题描述：" prop="problemDescription">
            <el-input
              v-model="form.problemDescription"
              type="textarea"
              rows="5"
              resize="none"
            ></el-input>
          </el-form-item>
          <div style="text-align: center">
            <el-button type="primary" @click="submitForm('form')"
              >提交</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import FooterTab from "../components/footer";
import {
  OnlineConsultingAdd,
  FrontendMenuList,
  ProductSearch,
  QueryProductByCategory,
} from "../api/index.js";
import { formatTime } from "../utils/util.js";
export default {
  components: {
    FooterTab,
  },
  data() {
    var ismobile = (rule, value, callback) => {
      var valid_rule =
        /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/;
      if (!value) {
        callback(new Error("请输入您的电话号码"));
      } else if (!valid_rule.test(value)) {
        callback(new Error("请输入正确的电话号码"));
      } else {
        callback();
      }
    };
    return {
      seach: "",
      onlineshow: false,
      rules: {
        name: {
          required: true,
          message: "请输入您的姓名",
          trigger: "blur",
        },
        mobile: {
          required: true,
          validator: ismobile,
          trigger: "blur",
        },
        problemDescription: {
          required: true,
          message: "请输入您的问题",
          trigger: "blur",
        },
      },
      form: {},
      // page: 'index',
      lang: localStorage.getItem("languageSet") === "en" ? "English" : "中文",
      languageType: 1,
      languageTxt: "中文",
      visible: false,
      banner: [
        {
          url: require("../assets/imges/index/2.png"),
          id: 1,
        },
        {
          url: require("../assets/imges/index/4.png"),
          id: 2,
        },
        {
          url: require("../assets/imges/index/6.png"),
          id: 4,
        },
        {
          url: require("../assets/imges/index/7.png"),
          id: 5,
        },
      ],
      activeIndex: "", // 第二层选中的值
      tabList: [],
      active: "", // 第一层选中的值
      active2: false,
      current: "j",
      current2: "s",
      l3: [],
      changeTxt: "改变语言",
      level2: [],
      level3: [],
      showIcon: false,
      newShow: false,
      chanShow: false,
      jieShow: false,
      yunShow: false,
      aboutShow: false,
      currt: false,
      currt2: false,
      newShow1: false,
      show: false,
    };
  },

  created() {
    FrontendMenuList().then((res) => {
      this.tabList = res.data;
    });
  },
  methods: {
    topage(val) {
      if (val.urlAddress) {
        if(val.urlAddress.slice(0,5) == 'http:' || val.urlAddress.slice(0,6) == 'https:'){
          // window.location.href = val.urlAddress
          window.open(val.urlAddress, '_blank');
        }else{
          this.$router.push(val.urlAddress);
        }
        this.show = false;
        this.$refs["popover"][0].doClose();
      }
    },
    topagetwo(productContentUrl) {
      this.$router.push(productContentUrl);
    },
    handleSelect(item) {
      this.$router.push(item.productContentUrl);
    },
    querySearch(queryString, cb) {
      ProductSearch({
        name: queryString,
      }).then((res) => {
        cb(res.data);
      });
    },

    // getpage(val){
    // 	this.page = val
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { name, mobile, problemDescription } = this.form;
          OnlineConsultingAdd({
            name,
            mobile,
            problemDescription,
            applicationTime: formatTime(new Date()),
            communicationStatusId: "2",
          }).then((res) => {
            if (res.code == 204) {
              this.$message.success("提交成功");
              this.onlineshow = false;
              this.form = {};
            }
          });
        }
      });
    },
    tologin() {
      window.location.href =
        "https://shengtaiyun.bonnidee.cn/index.php?s=/Admin/Login/index";
    },
    seeTable() {
      this.languageType = 1;
      this.languageTxt = "中文";
      this.$i18n.locale = "en";
      localStorage.setItem("languageSet", "en");
    },
    seeTable2() {
      this.languageType = 2;
      this.languageTxt = "英文";
      this.$i18n.locale = "zh";
      localStorage.setItem("languageSet", "zh");
    },
    newOver() {
      this.newShow = true;
    },
    mouse2Over() {
      this.newShow = true;
    },
    mouse2Leave() {
      this.newShow1 = false;
    },
    mouse3Over() {
      this.currt = true;
      this.currt2 = false;
    },
    //  mouse3Leave() {
    //    this.currt = false
    // },
    mouse4Over() {
      this.currt = false;
      this.currt2 = true;
    },
    //  mouse4Leave() {
    //    this.currt2 = false
    // },
    newMouse() {
      this.newShow = false;
    },
    newOver2() {
      this.newShow1 = true;
      this.chanShow = true;
    },
    newMouse2() {
      this.newShow1 = false;
      this.chanShow = false;
    },
    newOver3() {
      this.jieShow = true;
    },
    newMouse3() {
      this.jieShow = false;
    },
    newOver4() {
      this.yunShow = true;
    },
    newMouse4() {
      this.yunShow = false;
    },
    newOver5() {
      this.aboutShow = true;
    },
    newMouse5() {
      this.aboutShow = false;
    },
    // 秒杀
    miaosha() {
      localStorage.setItem("activity", "activity");
      this.$router.push("LatestActivity");
    },
    // 限量
    xianliang() {
      localStorage.setItem("activity", "bond");
      this.$router.push("LatestActivity");
    },

    overMenu(l1) {
      // if (l1.children.length != 0) {
      // 	this.level2 = l1.children;
      // 	this.active = l1.name;
      // } else {
      // 	this.level2 = [];
      // 	this.active = "";
      // }
      this.level2 = l1.children;
      // console.log(this.level2);
      if (this.level2.length > 0) {
        let id = this.level2[0].id;
        // this.QueryProductByCategory(id);
      }
      if (l1.maxLevel != 2) {
        this.show = true;
      }
      this.active = l1.name;
    },

    QueryProductByCategory(id) {
      QueryProductByCategory({
        ProductCategoryId: id,
      }).then((res) => {
        this.current = id;
        if (res.data.rows.length > 0) {
          this.level3 = res.data.rows;
        } else {
          this.level3 = [];
        }
      });
    },

    leaveMenu(l1) {
      this.active = "";
      this.activeIndex = "";
      this.level3 = [];
      // if(l1.maxLevel !=2){
      this.show = false;
      // }
    },

    toindex() {
      this.$router.push("/");
    },
    // 鼠标移入移除
    over(l2) {
      this.activeIndex = l2.name;
      if (l2.children.length > 0) {
        this.level3 = l2.children;
      }
      console.log(this.level3);
      // this.QueryProductByCategory(this.current);
    },
    leave() {
      this.l3 = "";
      // this.current = "";
    },
    over2(item) {
      // this.current = item.parentId;
    },
    leave2() {
      // this.current = "";
    },

    tuPush(tit) {
      this.active = "";
      switch (tit) {
        case "5G直播":
          localStorage.setItem("activity", "activity");
          this.$router.push("LiveStream");
          break;
        case "秒杀活动":
          localStorage.setItem("activity", "activity");
          this.$router.push("LatestActivity");
          this.break;
        case "限量神券":
          localStorage.setItem("activity", "bond");
          this.$router.push("LatestActivity");
          break;
        case "企业文化":
          this.$router.push("culture");
          break;
        case "联系我们":
          this.$router.push("relation");
          break;
        case "客户案例":
          this.$router.push("CustomerCase");
          break;
        case "加入我们":
          this.$router.push("welfare");
          break;
        case "集团介绍":
          this.$router.push("presentation");
          break;
      }
    },
  },
};
</script>
<style  lang="less">
.el-popover {
  min-width: 108px;
}
</style>

<style scoped lang="less">
.tabbar {
  position: initial;
  overflow-y: auto;
  width: auto;
}
.tabbaron {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.l3,
.l4 {
  width: 80%;
  position: fixed;
  max-height: 530px;
  padding-bottom: 25px;
  background: #fff;
  top: 110px;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 6px;
  color: #000000;
}

.l4 {
  display: flex;
  .l4table {
    background: #f4f4f7;
    width: calc(100% - 260px);
    height: 100%;
    border-radius: 0px 6px 6px 0px;
    .l4tablelist {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0px 60px;
      padding-top: 41px;
      .l4card {
        width: 200px;
        margin-right: 72px;
        margin-bottom: 40px;
        .l4heard {
          font-size: 12px;
          font-weight: 500;
          color: #2f7ffc;
          line-height: 17px;
          padding-bottom: 10px;
          border-bottom: 1px solid #e4e4e6;
        }
        .l4info {
          width: 157px;
          background: #eaecf0;
          border-radius: 2px;
          box-sizing: border-box;
          padding: 10px;
          padding-top: 0px;
          margin-top: 16px;

          .l4tableline {
            padding-top: 10px;
            font-size: 10px;
            font-weight: 100;
            line-height: 14px;
          }
          .l4tableline:hover {
            color: #2f7ffc;
          }
        }
      }
    }
  }
}

.l4list {
  width: 260px;
  padding-left: 50px;
  box-sizing: border-box;
  padding-top: 40px;
  .l4line {
    color: #616b80;
    margin-bottom: 22px;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    .l4listimg {
      width: 10px;
      float: right;
      margin-right: 50px;
      vertical-align: middle;
      margin-top: 4px;
    }
  }
  .l4lineone {
    color: #2f7ffc !important;
  }
}

.triangle-up {
  text-align: center;
  img {
    width: 22px;
  }
}

.threelevelsimg {
  width: 259px;
  height: 185px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.threelevels {
  display: flex;
  max-height: 530px;
  padding-top: 24px;
  padding-left: 50px;
  padding-right: 50px;
  flex-wrap: wrap;
  // overflow: auto;
  justify-content: space-between;
  max-width: 1444px;
  box-sizing: border-box;
  margin: auto;
  .threelevelcard {
    margin-bottom: 22px;
    flex: 0 0 12%;
    .threelevelhead {
      font-size: 12px;
      font-weight: 500;
      color: #2f7ffc;
      line-height: 17px;
    }
    .threelevelinfo {
      margin-top: 30px;
      .threeleveline {
        font-size: 12px;
        font-weight: 400;
        color: #616b80;
        margin-bottom: 12px;
      }
    }
  }
}

.l2 {
  margin-top: 10px;
  margin-bottom: 30px;
  // max-height: 205px;
  // overflow: auto;
  .l2text {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #2f7ffc;
    line-height: 17px;
    margin-top: 20px;
  }
}

.el-scrollbar__wrap {
  // overflow-x: hidden;
}

.threelevel {
  // height: 430px;
  color: #777777;
  width: 430px;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.headerbutton {
  position: absolute;
  right: 80px;
  top: 25px;

  .el-button--primary {
    line-height: 0;
    height: 35px;
    border: 2px solid #ffffff;
    background-color: rgba(248, 248, 255, 0);
  }
  /deep/.el-input__inner {
    height: 32px;
    line-height: 32px;
    width: 185px;
  }
  /deep/.el-input__icon {
    line-height: 32px;
  }

  .button {
    margin-left: 100px;
  }

  .input {
    width: 147px;
  }
}

.onlinelist {
  z-index: 111;
  width: 426px;
  height: 588px;
  position: fixed;
  right: 194px;
  top: 30%;
  background: #ffffff;
  border-radius: 14px;
  background-color: #f2f2f2;

  .onlinetable {
    width: 100%;
    height: 80px;
    background-image: url(../assets/imges/tabbar/1.png);
    background-size: cover;
    padding-top: 45px;
    box-sizing: border-box;

    .line {
      background-color: #fff;
      width: 24px;
      height: 4px;
      margin-left: 357px;
      cursor: pointer;
    }
  }

  .onlineform {
    margin-top: 35px;

    .el-button--primary {
      border: 2px solid #ffffff;

      // background-image: url(../assets/imges/tabbar/2.png);
      background: linear-gradient(270deg, #0a89ff 0%, #4371ff 100%);
    }
  }
}

.online {
  cursor: pointer;
  z-index: 111;
  width: 164px;
  // height: 52px;
  position: fixed;
  right: 0;
  top: 65%;
  border-radius: 8px 0px 0px 8px;
  font-size: 24px;
  font-weight: 500;
  padding: 5px 16px;
  padding-right: 0;
  background: #ffffff;
  box-sizing: border-box;

  img {
    vertical-align: middle;
    width: 26px;
    height: 26px;
  }

  span {
    margin-left: 11px;
    vertical-align: middle;
  }
}

.sdsss {
  line-height: 20px;
  display: flex;
  width: 326px;
  padding: 34px 0px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.sdssst {
  margin-left: 34px;
  line-height: 40px;
}

.sdssst:hover {
  color: #2f7ffc;
}

.tabbar {
  // font-size: 18px;
  // font-weight: 500;
  // font-family: Medium;
}

.top {
  color: #fff;
  /* height: 730px; */
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.top /deep/.el-input__inner {
  border-radius: 17px;
}

.outBox {
  width: 100%;
  // height: 130px;
  box-sizing: border-box;
  background: #000000;
  opacity: 0.09;
  position: absolute;
  z-index: 5;
  left: 0;
}

/* 改变语言 */
.el-dropdown {
  margin-left: 20px;
}

.el-dropdown-link {
  font-size: 14px;
  cursor: pointer;
  color: #fff;
}

.el-icon-arrow-down {
  font-size: 14px;
}

.el-dropdown-menu {
  top: 40px !important;
  left: 100px !important;
  margin: 0;
  padding: 0;
}

/* 头部文字 */
.topTxt {
  height: 42px;
  line-height: 42p;
  width: 100%;
  position: absolute;
  border-bottom: 2px solid #f5f5f5;
  z-index: 8;
  box-sizing: border-box;
}

.topTxt2 {
  font-size: 14px;
  position: absolute;
  left: 40px;
  top: 10px;
  z-index: 8;
}

/* 导航栏 */
.menu {
  position: absolute;
  left: 20%;
  font-size: 14px;
  font-family: Normal;
  cursor: default;
  height: 87px;
  -moz-user-select: none;
  /* Firefox私有属性 */
  -webkit-user-select: none;
  /* WebKit内核私有属性 */
  -ms-user-select: none;
  /* IE私有属性(IE10及以后) */
  -khtml-user-select: none;
  /* KHTML内核私有属性 */
  -o-user-select: none;
  /* Opera私有属性 */
  user-select: none;
  /* CSS3属性 */
}

.menul1 {
  display: inline-block;
  margin-left: 40px !important;
  // height: 94%;
  vertical-align: top;
  .menul1name {
    margin-top: 30px;
    margin-bottom: 4px;
  }
}

.menul1:nth-child(1) {
  margin-left: 0;
}

.menuAct {
  // border-bottom: 6px solid #ffda0a;
}

/* 子菜单 */
.menuChild {
  background: #fff;
  position: absolute;
}

.l2Child {
  height: 44px;
  line-height: 44px;
  padding-left: 10px;
  text-align: center;
  font-family: Normal;
}

.activeL2 {
  background: #fff;
  color: #2f7ffc;
}

.activeL3 {
  color: #2f7ffc;
}

.el-container {
  // height: 430px;
  color: #777777;
}

.el-aside {
  width: 140px !important;
  min-width: 115px !important;
  box-sizing: border-box;
  background: #f9f9f9;
  padding: 0 5px;
}

.el-container .is-vertical {
  width: 300px;
}

.el-header {
  height: 43px !important;
  padding: 0 40px;
  box-sizing: border-box;
}

.el-main {
  padding: 0;
  padding-left: 50px !important;
}

.menuTop {
  height: 100%;
  border-bottom: 3px solid rgba(200, 200, 200, 0.7);
  position: relative;
}

.menuImg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.zhibo {
  color: #2f7ffc;
  position: absolute;
  left: 60px;
  top: -20px;
  font-family: Medium;
}

/* 三级子菜单 */
.threeL {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  width: 80%;
  margin: 0 auto;

  // display: inline-block;
  span {
    margin-right: 31px;
  }

  span:hover {
    color: #2f7ffc;
  }
}

/* 导航栏 */
.logo {
  height: 87px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 42px;
  left: 0;
  height: 100px;
  z-index: 9;
}

.logo2 {
  position: absolute;
  left: 10%;
  top: 20px;

  img {
    width: 165px;
    height: 51px;
  }
}

/* 导航 */
.menuSpan {
  display: inline-block;
  height: 95%;
  line-height: 90px;
  /* padding: 0 5px; */
  margin-left: 30px;
  /* border-bottom: 6px solid #ffda0a; */
}

.activeMenu {
  border-bottom: 6px solid #ffda0a;
}

.menuIcon {
  margin-left: 4px;
}

/* 子级 */

/* 轮播图 */
/deep/.headerimg {
  width: 100%;
  vertical-align: bottom;
}

/* 标题 */
/deep/.title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}

/deep/.title2 {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #616b80;
  line-height: 33px;
  margin-top: 20px;
}

/deep/.errimg {
  width: 100%;
}

/deep/.contertop {
  margin-top: 140px;
}

/deep/.conterbottom{
   margin-bottom: 140px;
}



/deep/.advantagelist {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  .advantagecard {
    margin-left: 135px;
    .topandbottom{
      margin-top: 85px;
      margin-bottom: 85px;
    }
    .advantageline {
      .cardimg,
      .advantageinfo {
        display: inline-block;
        vertical-align: middle;
      }
      .cardimg {
        margin-right: 20px;
        img {
          width: 70px;
        }
      }
      .advantageinfo {
        .advantagetitle {
          font-size: 24px;
          font-weight: 500;
          line-height: 33px;
        }
        .cardtext {
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
          margin-top: 20px;
        }
      }
    }
  }
}

/deep/.information{
  background: #F5F5F9;
  padding-top: 140px;
  padding-bottom: 134px;
  .informationimg{
    margin-top: 58px;
    text-align: center;
    img{
      width: 1008px;
    }
  }
}
</style>
