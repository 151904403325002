<template>
  <div class="footer">
    <div class="innerBox">
      <div class="innerBox2">
        <!-- 左边信息 -->
        <div class="left">
          <div class="outTaem">
            <img src="../assets/imges/index/10.png" alt="" />
            <ul class="team">
              <li class="teamLi">邦伲德集团</li>
              <li class="teamLi">BONNIDEE</li>
            </ul>
          </div>
          <div class="outTaem2">
            <img src="../assets/imges/index/11.png" alt="" />
            <ul class="team2">
              <li class="teamLi22">服务热线</li>
              <li class="teamLi2">400-0365558</li>
            </ul>
          </div>
          <div class="outTaem3">
            <img src="../assets/imges/index/12.png" alt="" />
            <ul class="team3">
              <li class="teamLi3">公司地址</li>
              <li class="teamLi3">上海市闵行区顾戴路2337号维璟中心B座12层</li>
            </ul>
          </div>
        </div>
        <div class="middle">
          <div
            class="middleBox overflowd"
            v-for="(item, id) in footerList"
            :key="id"
          >
            {{ item.title }}
            <div
              class="middleBox2"
              @click="outsidechain(l2, item.title)"
              v-for="(l2, id) in item.children"
              :key="id"
            >
              {{ l2.name }}
            </div>
          </div>
        </div>
        <!-- 右边logo -->
        <div class="right">
          <div class="rightInfo">关注邦伲德</div>
          <div class="rightImg">
            <div class="rightImg2">
              <img src="../assets/imges/index/113.jpg" alt="" />
              <div class="imgInfo">小邦管家公众号</div>
            </div>
            <div class="rightImg3">
              <img src="../assets/imges/index/14.png" alt="" />
              <div class="imgInfo">邦伲德官微号</div>
            </div>
          </div>
          <div class="rightLast">
            <div class="rightLast2">法律声明及隐私权政策</div>
            <div class="rightLast3">法律顾问:邦伲德法务部</div>
          </div>
        </div>
      </div>
      <!-- 回首页 -->
      <div class="backHome">
        <span
          class="backHome2"
          @click="goPath(item.name)"
          v-for="(item, id) in footerTab"
          :key="id"
          >{{ item.name }}</span
        >
        <el-popover placement="top" trigger="hover">
          <div>
            <div class="ahref">
              <a href="http://www.0731hdd.com" target="_blank"
                >服务器阵列数据恢复</a
              >
            </div>
            <div class="ahref">
              <a href="https://www.ycwlgs.com" target="_blank">盐城网站建设 </a>
            </div>
          </div>
          <span class="backHome2" slot="reference"> 友情链接</span>
        </el-popover>
      </div>
    </div>
    <!-- 最底部 -->
    <div class="lastBox">
      邦伲德（上海）网络技术有限公司 版权所有
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        style="color: #cdcdcd; text-decoration: none"
        target="_blank"
        >沪ICP备17013671号-1</a
      >
      © 2022 邦伲德 版权所有
      <img
        @click="backTop"
        class="topImg"
        src="../assets/imges/index/top.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { PartnersList, SearchHot } from "../api/footer.js";
export default {
  data() {
    return {
      list: [
        {
          title: "热门产品",
          id: 10,
          children: [
            {
              txt: "生态云",
              id: 1,
            },
            {
              txt: "CallBaby",
              id: 2,
            },
            {
              txt: "C-witch",
              id: 3,
            },
            {
              txt: "电商平台",
              id: 4,
            },
            {
              txt: "社群系统",
              id: 5,
            },
            {
              txt: "5G直播",
              id: 6,
            },
          ],
        },
        {
          title: "合作伙伴",
          id: 11,
          children: [
            {
              txt: "ROBAM老板",
              id: 21,
            },
            {
              txt: "恒安集团",
              id: 22,
            },
            {
              txt: "中旅总社",
              id: 23,
            },
            {
              txt: "电商平台",
              id: 24,
            },
            {
              txt: "花琅直播",
              id: 25,
            },
          ],
        },
        {
          title: "常见问题",
          id: 12,
          children: [
            {
              txt: "帮助中心",
              id: 31,
            },
            {
              txt: "备案管理",
              id: 32,
            },
            {
              txt: "注册登录",
              id: 33,
            },
            {
              txt: "联系我们",
              id: 34,
            },
          ],
        },
        {
          title: "关于邦伲德",
          id: 13,
          children: [
            {
              txt: "集团介绍",
              id: 41,
            },
            {
              txt: "企业文化",
              id: 42,
            },
            {
              txt: "集团动态",
              id: 43,
            },
            {
              txt: "加入我们",
              id: 44,
            },
            {
              txt: "合作伙伴计划",
              id: 45,
            },
            // {
            // 	txt: "客户案例",
            // 	id: 46
            // },
          ],
        },
      ],
      footerList: [
        {
          title: "热门产品",
          id: 1,
          children: [
            {
              name: "生态云",
              id: 1 - 1,
            },
            {
              name: "Callbaby",
              id: 1 - 2,
            },
            {
              name: "C-witch",
              id: 1 - 3,
            },
            {
              name: "电商平台",
              id: 1 - 4,
            },
            {
              name: "电商平台",
              id: 1 - 5,
            },
            {
              name: "社群系统",
              id: 1 - 6,
            },
            {
              name: "5G直播",
              id: 1 - 7,
            },
          ],
        },
        {
          title: "合作伙伴",
          id: 2,
          children: [],
        },
        {
          title: "常见问题",
          id: 3,
          children: [
            {
              name: "帮助中心",
              id: 3 - 1,
            },
            {
              name: "备案管理",
              id: 3 - 2,
            },
            {
              name: "注册登录",
              id: 3 - 3,
            },
            {
              name: "联系我们",
              id: 3 - 4,
            },
          ],
        },
        {
          title: "关于邦伲德",
          id: 4,
          children: [
            {
              name: "集团介绍",
              id: 4 - 1,
            },
            {
              name: "企业文化",
              id: 4 - 2,
            },
            {
              name: "集团动态",
              id: 4 - 3,
            },
            {
              name: "加入我们",
              id: 4 - 4,
            },
            {
              name: "合作伙伴计划",
              id: 4 - 5,
            },
            // {
            // 	title: "客户案列",
            // 	id: 4 - 6
            // },
          ],
        },
      ],
      footerTab: [
        {
          id: 1,
          name: "首页",
        },
        {
          id: 2,
          name: "关于我们",
        },
        {
          id: 3,
          name: "联系我们",
        },
        {
          id: 4,
          name: "付款方式",
        },
        {
          id: 5,
          name: "服务条款",
        },
        {
          id: 6,
          name: "会员中心",
        },
      ],
    };
  },
  mounted() {
    PartnersList().then((res) => {
      this.footerList[1].children = res.data;
    });
    SearchHot().then((res) => {
      this.footerList[0].children = res.data;
    });
  },
  methods: {
    backTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    outsidechain(val, title) {
      if (title == "热门产品") {
        this.$router.push(val.productContentUrl);
      } else if (title == "合作伙伴") {
        var tempwindow = window.open("_blank");
        tempwindow.location = val.sitesLinks;
      } else {
        this.pushTo(val.name);
      }
    },
    pushTo(tit) {
      switch (tit) {
        case "集团介绍":
          this.$router.push("/presentation");
          break;
        case "企业文化":
          this.$router.push("/culture");
          break;
        case "5G直播":
          this.$router.push("/liveStream");
          break;
        case "集团动态":
          this.$router.push("/dynamic");
          break;
        case "客户案列":
          this.$router.push("/CustomerCase");
          break;
        case "加入我们":
          this.$router.push("/welfare");
          break;
        case "联系我们":
          this.$router.push("/relation");
          break;
        case "解决方案":
          this.$router.push("/scheme");
          break;
        case "合作伙伴计划":
          this.$router.push("/partner");
          break;
        // case '联系我们':
        //   this.$router.push('/relation')
        // break;
      }
    },
    goPath(tit) {
      switch (tit) {
        case "首页":
          this.$router.push("/");
          break;
        case "关于我们":
          break;
        case "联系我们":
          this.$router.push("relation");
          break;
        case "付款方式":
          break;
        case "服务条款":
          break;
        case "会员中心":
          break;
      }
    },
  },
};
</script>

<style leng='less' scoped>
.overflowd {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  width: 100%;
  background: #0e1726;
  box-sizing: border-box;
  color: #fff;
  box-sizing: border-box;
}

.innerBox {
  padding-top: 90px;
  width: 75%;
  margin-left: 15%;
  margin-right: 30%;
  position: relative;
}

.innerBox2 {
  width: 100%;
  position: relative;
  display: flex;
}

.lastBox {
  margin-top: 20px;
  height: 112px;
  line-height: 112px;
  text-align: center;
  border-top: 1px solid #313131;
  font-family: Medium;
  font-size: 16px;
  color: #cdcdcd;
  position: relative;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
}

.left {
  padding-top: 6px;
  position: relative;
  width: 300px;
}

/* 右边logo */
.right {
  width: 300px;
}

.rightInfo {
  font-family: Medium;
  padding-bottom: 24px;
  font-size: 20px;
  font-weight: bold;
}

.rightImg {
  display: flex;
  justify-content: space-between;
}
.rightImg2 {
  text-align: center;
}
.rightImg img {
  width: 120px;
}

.rightImg3 {
  padding-left: 40px;
  text-align: center;
}

.imgInfo {
  font-size: 14px;
  font-family: Normal;
  color: #cccccc;
  line-height: 18px;
  letter-spacing: 2px;
}

.rightLast {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #cdcdcd;
  line-height: 24px;
  padding-top: 20px;
}

/* 右边logo */
/* 中间 */
.middle {
  flex: 1;
  display: flex;
  justify-content: space-around;
}

.middleBox {
  font-size: 20px;
  font-family: Medium;
  font-weight: bold;
  color: #ffffff;
  line-height: 30px;
}

.middleBox2 {
  font-size: 16px;
  font-family: Normal;
  font-weight: 400;
  color: #cdcdcd;
  line-height: 24px;
  padding: 10px 0;
  cursor: pointer;
  width: 188px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 中间 */
.topImg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
}

.fuwu {
  position: absolute;
  right: 0;
}

.outTaem {
  position: relative;
  width: 300px;
}

.outTaem img {
  width: 71px;
}

.outTaem2 {
  position: relative;
  margin-top: 60px;
  width: 300px;
}

.outTaem2 img {
  width: 40px;
}

.outTaem3 {
  position: relative;
  margin-top: 60px;
  width: 300px;
}

.outTaem3 img {
  width: 40px;
}

.team {
  color: #fff;
  font-size: 18px;
  position: absolute;
  left: 100px;
  top: -10px;
  z-index: 9;
  font-family: Medium;
}

.team2 {
  position: absolute;
  top: -5px;
  left: 70px;
}

.team3 {
  font-family: Medium;
  position: absolute;
  top: -10px;
  left: 70px;
}

.teamLi,
.teamLi2,
.teamLi3 {
  padding: 3px 0;
}

.teamLi2 {
  font-family: Bold;
  font-size: 24px;
  font-weight: 600;
}

.teamLi22 {
  font-size: 16px;
}

.teamLi3 {
  font-weight: 500;
  font-size: 16px;
}

/* 首页 关于我们 联系我们 */
.backHome {
  width: 100%;
  text-align: center;
  color: #cdcdcd;
  font-size: 16px;
  padding-top: 50px;
  cursor: pointer;
}

.backHome2 {
  padding-right: 20px;
  margin-right: 10px;
  border-right: 2px solid #cdcdcd;
}

.ahref {
  margin-bottom: 15px;
}

.ahref a {
  text-decoration: none;
  color: black;
}
</style>
